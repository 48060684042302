import React, { useState, useEffect } from 'react';
import { FadeIn } from "./FadeIn";
import { ArrowUpRight, Info } from 'lucide-react';
import { format } from 'date-fns';


const Holdings = () => {
  const [topHoldings, setTopHoldings] = useState<any>({});

  const fetchHoldings = async () => {
    const response = await fetch(`${process.env.GATSBY_INDEX_DATA_API_URI}/NYSEOVC/holdings.json?v=1724089714961247`);  // TODO update URL /api/index/NYSEOVC/holdings.json
    const data = await response.json();
    return data;
  }

  useEffect(() => {
    fetchHoldings()
      .then((data) => {
        console.log('Successfully fetched data:', data);
        setTopHoldings(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function formatDate(dateString: string | number | Date | undefined) {
      if (!dateString) {
        return undefined;
      }
      const date = new Date(dateString);
      return format(date, 'MMM d, yyyy');
  }

  return (
    <div className="bg-gray-50 py-16 sm:py-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <FadeIn>
            <h2 className="text-center text-base font-semibold leading-7 text-gray-900 mb-6">Index Information</h2>
        </FadeIn>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mb-16">
          <FadeIn>
            <OverviewCard
              title="Number of Holdings"
              value={topHoldings?.indexOverview?.numberOfHoldings}
              info="Total number of companies in the index"
            />
          </FadeIn>
          <FadeIn>
            <OverviewCard
              title="Top 10 Weight"
              value={topHoldings?.indexOverview?.top10Weight * 100 + '%'}
              info="Combined weight of the top 10 constituents"
            />
          </FadeIn>
          <FadeIn>
            <OverviewCard
              title="Calculation date"
              value={formatDate(topHoldings?.indexOverview?.lastDate)}
              info="Date when the index was last calculated"
            />
          </FadeIn>
        </div>
        <FadeIn>
          {/* <h2 className="text-base font-semibold leading-7 text-primary mb-4">Top Holdings</h2> */}
          <h2 className="text-center text-base font-semibold leading-7 text-gray-900 mb-6">Top Constituents</h2>
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rank</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">OPEN Identifier</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {topHoldings?.topHoldings?.map((company: any) => (
                  <tr key={company.rank} className="hover:bg-gray-50 transition-colors duration-150">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{company.rank}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <img className="h-10 w-10 rounded-full" src={company.logo} alt={`${company.name} logo`} />
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{company.name}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{company.identifier}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </FadeIn>
      </div>
    </div>
  );
}

const OverviewCard = ({ title, value, info }) => (
  <div className="bg-white rounded-lg p-6 shadow-md border border-gray-200 relative group">
    <h3 className="text-sm font-medium text-gray-500 uppercase mb-2">{title}</h3>
    <p className="text-3xl font-bold text-gray-900">{value}</p>
    <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
      <div className="relative">
        <Info className="h-5 w-5 text-gray-400 cursor-pointer" />
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg p-2 text-xs text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          {info}
        </div>
      </div>
    </div>
  </div>
);

export default Holdings;